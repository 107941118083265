import * as THREE from 'three'
import * as CANNON from 'cannon-es'
import { world, ballMaterial } from './physics'
import stateManager from './state-manager'
import { Ball } from './ball'

export default class BallFactory {
   constructor(scene, textures) {
      this.scene = scene
      let radius = 0.4
      this.ballRadiusDifference = 0.25
      this.geometries = [
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
         new THREE.SphereGeometry(radius += this.ballRadiusDifference, 32, 16),
      ]
      this.materials = [
         new THREE.MeshLambertMaterial({map: textures.colorTexture00}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture01}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture02}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture03}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture04}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture05}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture06}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture07}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture08}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture09}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture10}),
         new THREE.MeshLambertMaterial({map: textures.colorTexture11}),
      ]
      this.colors = [
         new THREE.Color(0xb37fa7),
         new THREE.Color(0x52d4fc),
         new THREE.Color(0xbcc1d0),
         new THREE.Color(0xf2cbc9),
         new THREE.Color(0xc2a171),
         new THREE.Color(0xab49ae),
         new THREE.Color(0x2091a8),
         new THREE.Color(0x91de81),
         new THREE.Color(0xe28159),
         new THREE.Color(0xa279f6),
         new THREE.Color(0x55c3cb),
         new THREE.Color(0xf1d148), 
      ]
      this.defaultStartingPosition = {x: 0, y: stateManager.getBallDropHeight(), z: 0}
   }

   /**
    * 
    * @param {CANNON.Body} body The cannon body to check
    * @param {Number} [threshold=0.1] The distance within which bodies are considered to be nearby
    * @returns {Object} An object containing the future merge opportunities within range and their distances, as well as the distance of the closest of those bodies
    */
   futureMergeOpportunities(body, threshold = 0.1) {
      const matchingBodies = world.bodies.filter(b => 
         b._ballType == body._ballType + 1 &&
         b.type == CANNON.BODY_TYPES.DYNAMIC
      )
      const nearby = []
      // One body has radius type N and the other has radius type N+1
      const twoBodySpan = body.boundingRadius + this.geometries[body._ballType + 1].parameters.radius
      const nearEnough = twoBodySpan + threshold
      let closest = Number.POSITIVE_INFINITY
      for(const b of matchingBodies) {
         const distance = body.position.distanceTo(b.position)
         if(distance < nearEnough) {
            nearby.push({
               body: b,
               distance
            })
            if(distance < closest) {
               closest = distance
            }
         }
      }
      return {
         closest,
         nearby
      }
   }

   /**
    * @param {CANNON.Body} body The cannon body to check for neighbors
    * @param {CANNON.Body} [include=null] The body which was collided with, if one exists, to be automatically included
    * @param {Number} [threshold=0.15] The distance within which bodies are considered to be nearby
    * @returns {Array<CANNON.Body>} The list of bodies in close proximity to body
    */
   getNearbyMatchingBodies(body, include = null, threshold = stateManager.state.nearbyMatchDistance) {
      // Get all the matching balls, other than those passed in, not including currentBall
      const matchingBodies = world.bodies.filter(b => 
         b._ballType == body._ballType &&
         b.type == CANNON.BODY_TYPES.DYNAMIC &&
         b.id != body.id &&
         (include && b.id != include.id ? true : false)
      )
      const nearby = include ? [include] : []
      /**
       * We get the radius from this.geometries because the ball's bounding radius
       * may be smaller than normal if it's in the middle of merging.
       * This is necessary for proper nearby body finding in the case
       * of a chain merge.
       */
      const radius = this.geometries[body._ballType].parameters.radius
      const nearEnough = (radius * 2) + threshold
      for(const b of matchingBodies) {
         if(body.position.distanceTo(b.position) < nearEnough) {
            nearby.push(b)
         }
      }
      /**
       * Ensure that a multimerge never results in the game trying to 
       * create a ball that is larger than the maximum.
       */
      const maxArraySize = this.geometries.length - body._ballType - 1
      return nearby.slice(0, maxArraySize)
   }

   makeBall(type, position = this.defaultStartingPosition, isStatic = true, expandFunction = null, impulseVector = {x: 0, y: 0, z: 0}) {
      // If the ball will expand, set its radius to that of its predecessor then scale it up
      const geometryType = !!expandFunction && type > 0 ? type - 1 : type
      const isMaxSize = type < this.geometries.length - 1 ? false : true
      const shape = new CANNON.Sphere(this.geometries[geometryType].parameters.radius)
      const body = new CANNON.Body({
         mass: 1,
         type: isStatic ? CANNON.Body.STATIC : CANNON.Body.DYNAMIC,
         position,
         shape,
         material: ballMaterial
      })
      body._ballType = type
      // If this ball will be currentBall, let other balls pass through it.
      if(isStatic) {
         body.collisionFilterMask = 2
      }
      world.addBody(body)
      const mesh = new THREE.Mesh(this.geometries[geometryType], this.materials[type])
      mesh.castShadow = true
      mesh.receiveShadow = true
      mesh.position.copy(body.position)
      // So we can easily get the mesh when we only have the body
      body._meshUUID = mesh.uuid
      // To make it easy to determine what size ball will replace this one
      mesh._ballType = type
      this.scene.add(mesh)
      // Face the camera a bit
      mesh.rotateY(Math.PI * -0.5)
      body.quaternion.copy(mesh.quaternion)

      const ball = new Ball(mesh, body, this.colors[type])

      ball.body.addEventListener('collide', (event) => {
         // If the ball is max size, prevent merge
         if(isMaxSize) {
            return false
         }
         // If we've already handled this collision
         if(event.target._isPrimary || event.body._isPrimary) {
            return false
         }
         if(event.target._isSecondary || event.body._isSecondary) {
            return false
         }
         // If both objects are balls in play (i.e. neither container nor currentBall)
         if(event.body.type == CANNON.BODY_TYPES.DYNAMIC && event.target.type == CANNON.BODY_TYPES.DYNAMIC) {
            if(event.target._ballType == event.body._ballType) {
               /**
                * Whichever body has the most like neighbors will be the one that gets
                * merged into. If only one neighbor (the other of the collision pair),
                * we select event.target (first-come, first-serve w/r/t collision events).
                */
               const targetNearbyBodies = this.getNearbyMatchingBodies(event.target, event.body)
               const bodyNearbyBodies = this.getNearbyMatchingBodies(event.body, event.target)
               const detail = {}
               if(targetNearbyBodies.length > bodyNearbyBodies.length) {
                  event.target._isPrimary = true
                  detail.thisBall = event.target,
                  detail.thoseBalls = targetNearbyBodies
               }
               else if (bodyNearbyBodies.length > targetNearbyBodies.length) {
                  event.body._isPrimary = true
                  detail.thisBall = event.body,
                  detail.thoseBalls = bodyNearbyBodies
               }
               else {
                  /**
                   * If both balls have the same number of like neighbors, we choose the 
                   * ball to merge toward based on how many type + 1 neighbors each has.
                   * If they have the same number of type + 1 neighbors (> 0),
                   * we pick the ball with the closest type + 1 neighbor.
                   * In other words, we try to merge toward future merge opportunities.
                   */
                  const {nearby: futureTargetNearbies, targetClosest} = 
                     this.futureMergeOpportunities(event.target, 0.1)
                  const {nearby: futureBodyNearbies, bodyClosest} = 
                     this.futureMergeOpportunities(event.body, 0.1)

                  if(futureTargetNearbies.length > futureBodyNearbies.length) {
                     event.target._isPrimary = true
                     detail.thisBall = event.target,
                     detail.thoseBalls = targetNearbyBodies
                  }
                  else if((futureBodyNearbies.length > futureTargetNearbies.length)) {
                     event.body._isPrimary = true
                     detail.thisBall = event.body,
                     detail.thoseBalls = bodyNearbyBodies
                  }
                  // If both have the same number of future merge opportunities
                  else {
                     if(targetClosest >= bodyClosest) {
                        event.target._isPrimary = true
                        detail.thisBall = event.target,
                        detail.thoseBalls = targetNearbyBodies
                     }
                     else {
                        event.body._isPrimary = true
                        detail.thisBall = event.body,
                        detail.thoseBalls = bodyNearbyBodies
                     }
                  }
               }
               /**
                * Ensure that all balls involved in this collision will take part
                * in only one merge, regardless of any other collisions they
                * may be involved in.
                */
               for(const b of detail.thoseBalls) {
                  b._isSecondary = true
               }
               stateManager.getCanvas().dispatchEvent(new CustomEvent('requestBallMerge', { detail }))
               return true
            }
         }
         return false
      })

      if(!!expandFunction) {
         expandFunction(ball, this.geometries[geometryType].parameters.radius, this.geometries[type].parameters.radius)
      }
      // The merger of balls should apply a small impulse to the new ball in the direction of the merger.
      ball.body.applyLocalImpulse(impulseVector, {x: 0, y: 0, z: 0})
      return ball
   }
}