import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TitleScreenView from '../views/TitleScreenView.vue'
import { store } from '../store'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter: () => {
        if(store.showTitleScreen) {
          return { name: 'title-screen' }
        }
      }
    },
    {
      path: '/',
      name: 'title-screen',
      component: TitleScreenView
    },
    {
      path: '/how-to-play',
      name: 'how-to-play',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/HowToPlayView.vue')
    },
    {
      path: '/learning-center',
      name: 'learning-center',
      component: () => import('../views/LearningCenterView.vue')
    },
    {
      path: '/options',
      name: 'options',
      component: () => import('../views/OptionsView.vue')
    }
  ]
})

export default router