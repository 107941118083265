<template>
   <div class="next-up">
      <div :class="{ 'img-container': true, 'new-value-flash': newValue }">
         <img :src="imageData.src" class="next-up-img">
      </div>
      <span class="ui-text">Next</span>
   </div>
</template>

<script setup>
import { ref } from 'vue'

const newValue = ref(false)

const imageData = ref({
   src: '/headshots/headshot_0.png',
   alt: 'ball 1'
})

window.addEventListener('nextBallUpdated', ({ detail }) => {
   imageData.value.src = detail.src
   imageData.value.alt = detail.alt

   newValue.value = true
   setTimeout(() => {
      newValue.value = false
   }, 50)
})
</script>

<style scoped>
.next-up {
  width: var(--ui-box-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.next-up .img-container {
  width: var(--ui-box-height);
  height: var(--ui-box-height);
  border: var(--ui-box-border);
  border-radius: var(--ui-box-border-radius);
}
.next-up img {
  width: 100%;
  height: 100%;
}

.new-value-flash {
   background-color: var(--ui-box-border-color);
}
</style>