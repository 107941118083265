<template>
   <div class="score">
      <div class="points-box">
         <p class="points">{{ points }}</p>
      </div>
      <span class="ui-text">Score</span>
   </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
   initialPoints: Number
})

const points = ref(props.initialPoints)

window.addEventListener('scoreUpdated', ({ detail }) => {
   points.value = detail.points
})
</script>

<style scoped>
.score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  user-select: none;
}
.points-box {
  width: 100%;
  height: var(--ui-box-height);
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--ui-box-border);
  border-radius: var(--ui-box-border-radius);
}
.points {
  font-size: 30px;
}
</style>