<template>
   <main class="title-screen">
      <LoadingScreen v-if="!store.texturesLoaded || !store.audioLoaded"></LoadingScreen>
      <button class="title-screen-button" @click="startNewGame">New Game</button>
      <button class="title-screen-button" @click="continueGame" :disabled="stateManager.getTurn() < 1">Continue</button>
      <button class="title-screen-button" @click="loadSave" :disabled="!store.saveExists">Load Autosave</button>
      <button class="title-screen-button" @click="goToOptions">Options</button>
   </main>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { store } from '../store.js'
import { audioManager } from '../../audio'
import stateManager from '../../state-manager'
import LoadingScreen from '../components/LoadingScreen.vue'

const router = useRouter()

const startNewGame = function() {
   /** Restart the game if there's one in progress */
   if(stateManager.getTurn() > 0) {
      stateManager.resetGame()
   }
   store.showTitleScreen = false
   store.gameInProgress = true
   if(!audioManager.musicIsPlaying) {
      audioManager.playMusic()
   }
   router.push({ name: 'home' })
}
const continueGame = function() {
   store.showTitleScreen = false
   store.gameInProgress = true
   if(!audioManager.musicIsPlaying) {
      audioManager.playMusic()
   }
   router.push({ name: 'home' })
}
const loadSave = function() {
   store.showTitleScreen = false
   store.gameInProgress = true
   if(!audioManager.musicIsPlaying) {
      audioManager.playMusic()
   }
   stateManager.loadSave()
   router.push({ name: 'home' })
}
const goToOptions = function() {
   router.push({ name: 'options' })
}


const headTitle = 'Threeka'
const headDescription = 'A game about planets.'
const headURL = `${window.location.href}`

useHead({
   title: headTitle,
    meta: [
      { name: 'description', content: headDescription },
      { property: 'og:title', content: headTitle },
      {
        property: 'og:url',
        content: headURL,
      },
      {
        property: 'og:description',
        content: headDescription,
      },
      {
        property: 'twitter:url',
        content: headURL,
      },
      {
        property: 'twitter:title',
        content: headTitle,
      },
      {
        property: 'twitter:description',
        content: headDescription,
      },
    ]
})
</script>

<style scoped>
.title-screen {
   position: absolute;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 20px;
   width: 100vw;
   height: 100vh;
   background-color: var(--bg-color-dark);
   z-index: 2;
}
.title-screen-button {
   height: min(15vw, 50px);
   width: min(80vw, 200px);
   font-size: 1.2rem;
   border: var(--ui-box-border);
   border-radius: var(--ui-box-border-radius);
   color: var(--text-color-primary);
   background-color: var(--bg-color-light);
}

.title-screen-button:disabled {
   color: rgba(89, 206, 89, 0.2);
   background-color: rgba(17, 17, 34, 0.6);
   border-color: rgba(0, 187, 255, 0.2);
}

</style>