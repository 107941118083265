<template>
   <div class="vue-app">
      <SidebarMenu></SidebarMenu>
      <router-view v-slot="{ Component }">
         <component :is="Component" @scroll="handleScroll" />
      </router-view>
   </div>
</template>

<script setup>
import { store } from './store'
import { RouterView } from 'vue-router'
import { useHead } from '@unhead/vue'
import SidebarMenu from './components/SidebarMenu.vue'

useHead({
   title: 'Threeka',
   meta: [
      {
         name: 'og:type',
         content: 'website'
      },
      {
         name: 'og:site_name',
         content: 'Threeka'
      },
      {
         name: 'og:image',
         content: `${window.location.href}/threeka-link-share-1200x630.png`
      },
      {
         name: 'og:image:alt',
         content: 'image/png'
      },
      {
         name: 'og:image:type',
         content: 'image/png'
      },
      {
         name: 'og:image:width',
         content: '1200'
      },
      {
         name: 'og:image:height',
         content: '630'
      },
      {
         name: 'og:locale',
         content: 'en_US'
      },
      {
         name: 'twitter:card',
         content: 'summary_large_image'
      },
      {
         name: 'twitter:image',
         content: `${window.location.href}/threeka-link-share-1200x630.png`
      }
   ]
})

const handleScroll = function(event) {
   if(event.target.scrollTop > 50) {
      store.showMenuButton = false
   }
   else {
      store.showMenuButton = true
   }
}
</script>

<style scoped>
</style>