import * as THREE from 'three'
import stateManager from "./state-manager"

export class DropWindow {
   constructor(scene, camera, width, depth, containerHeight) {
      this.scene = scene
      this.camera = camera
      this.width = width
      this.depth = depth
      this.containerHeight = containerHeight
      this.dropWindowHeight = containerHeight * 0.5
      /** Used to ensure balls can't touch the walls of the container on drop */
      this.minDropGap = 0.001
      this.locatorBeaconHeight = 0.03

      this.window = this.makeDropWindow()
      this.gridLines = this.makeGridLines()
      this.locatorBeacon = this.makeLocatorBeacon()
      this.locatorDropLine = this.makeLocatorDropLine()

      this.scene.add(this.window, this.gridLines, this.locatorBeacon, this.locatorDropLine)

      this.pointer = new THREE.Vector2()
      this.raycaster = new THREE.Raycaster()

      stateManager.getCanvas().addEventListener('ballAvailableToDrop', () => {
         const radius = stateManager.getCurrentBall().body.boundingRadius
   
         this.locatorBeacon.scale.set(radius, radius, radius)
         this.locatorBeacon.position.x = stateManager.getCurrentBall().mesh.position.x
         this.locatorBeacon.position.z = stateManager.getCurrentBall().mesh.position.z
         this.locatorBeacon.material.color = stateManager.getCurrentBall().color
   
         this.locatorDropLine.position.x = stateManager.getCurrentBall().mesh.position.x
         this.locatorDropLine.position.z = stateManager.getCurrentBall().mesh.position.z
         scene.add(this.locatorDropLine)
      })

      stateManager.getCanvas().addEventListener('requestBallDrop', () => {
         scene.remove(this.locatorDropLine)
      })
   
      stateManager.getCanvas().addEventListener('pointerdown', (event) => {
         const dropWindowIntersection = this.getDropWindowIntersection(event)
         if(dropWindowIntersection){
            event.stopPropagation()
   
            if(stateManager.getCurrentBall()) {
               const point = this.getBoundedIntersectionPoint(dropWindowIntersection, stateManager.getCurrentBall())
               this.setBallAndLocatorPosition(point)
   
               stateManager.activateDropMode(event.pointerType)
            }
         }
         else if(this.getCurrentBallIntersection(event)) {
            event.stopPropagation()
            if(stateManager.getCurrentBall()) {
               const point = stateManager.getCurrentBall().mesh.position
               point.y = this.window.position.y
               this.setBallAndLocatorPosition(point)
   
               stateManager.activateDropMode(event.pointerType)
            }
         }
      }, { capture: true })
   
      stateManager.getCanvas().addEventListener('pointermove', (event) => {
         if(stateManager.gameIsSuspended()) {
            return false
         }
         /**
          * If the pointer type is 'touch' or 'pen', we move the ball by clicking and dragging.
          * If the pointer type is 'mouse', the ball is free to move to the mouse's location within bounds.
          */
         if(event.pointerType != 'mouse' && !stateManager.getdropModeStatus()) {
            return false
         }
         const dropWindowIntersection = this.getDropWindowIntersection(event)
         if(dropWindowIntersection && !!stateManager.getCurrentBall()) {    
            const point = this.getBoundedIntersectionPoint(dropWindowIntersection, stateManager.getCurrentBall())
            this.setBallAndLocatorPosition(point)
         }
         else if(this.getCurrentBallIntersection(event)) {
            const point = stateManager.getCurrentBall().mesh.position
            point.y = this.window.position.y
            this.setBallAndLocatorPosition(point)
         }
      })
   
      stateManager.getCanvas().addEventListener('pointerup', (event) => {
         if(stateManager.getdropModeStatus()) {
            stateManager.getCanvas().dispatchEvent(new Event('requestBallDrop'))
            stateManager.deactivateDropMode()
         }  
      }, { capture: true })
   
      window.addEventListener('gameOver', ({ detail }) => {
         this.handleEndGame()
      })
   }

   handleEndGame() {
      this.scene.remove(this.locatorBeacon)
      this.scene.remove(this.locatorDropLine)
      this.scene.remove(this.gridLines)
      this.window.material.opacity = 0.85
      this.window.material.side = THREE.DoubleSide
   }

   handleResetGame() {
      this.locatorBeacon.position.set(0, this.dropWindowHeight + this.locatorBeaconHeight, 0)
      this.scene.add(this.locatorBeacon)
      this.scene.add(this.gridLines)
      this.window.material.opacity = 0
      this.window.material.side = THREE.FrontSide
   }

   makeDropWindow() {
      const dropWindow = new THREE.Mesh(
         new THREE.PlaneGeometry(this.width, this.depth),
         new THREE.MeshToonMaterial({ transparent: true, opacity: 0, color: 0x337799, side: THREE.FrontSide })
      )
      dropWindow.position.set(0, this.dropWindowHeight, 0)
      dropWindow.rotateX(Math.PI * -0.5)
      return dropWindow
   }
   makeGridLines() {
      const textureLoader = new THREE.TextureLoader()
      const gridTexture = textureLoader.load('/textures/drop-window-grid-texture.png')
      gridTexture.colorSpace = THREE.SRGBColorSpace

      const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.25, color:0xffffff, side: THREE.DoubleSide, map: gridTexture })
      const zAxisLines = new THREE.Mesh(
         new THREE.PlaneGeometry(this.width, this.depth),
         material
      )
      const xAxisLines = new THREE.Mesh(
         new THREE.PlaneGeometry(this.depth, this.width),
         material
      )
      xAxisLines.rotateZ(Math.PI * 0.5)
      /** Ensure that the two planes don't z-fight */
      xAxisLines.position.z += 0.01
      const grid = new THREE.Group()
      grid.add(zAxisLines, xAxisLines)
      grid.rotateX(Math.PI * 0.5)
      grid.position.y = this.dropWindowHeight + 0.01
      return grid
   }
   makeLocatorBeacon() {
      const locatorBeacon = new THREE.Mesh(
         new THREE.CircleGeometry(1, 32),
         new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0.5,
            color: 0xffffff,
            side: THREE.DoubleSide,
         })
      )
      locatorBeacon.position.set(0, this.dropWindowHeight + this.locatorBeaconHeight, 0)
      locatorBeacon.rotateX(Math.PI * -0.5)
      return locatorBeacon
   }
   makeLocatorDropLine() {
      const locatorDropLine = new THREE.Mesh(
         new THREE.CylinderGeometry(0.05, 0.05, this.containerHeight + 2.5, 8),
         new THREE.MeshBasicMaterial({
            color: 0x979ace,
         })
      )
      locatorDropLine.position.y += 1.25
      return locatorDropLine
   }

   getDropWindowIntersection(event) {
      this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
	   this.pointer.y = (event.clientY / window.innerHeight) * -2 + 1;
      this.raycaster.setFromCamera(this.pointer, this.camera)
      const intersection = this.raycaster.intersectObject(this.window)
      return intersection.length ? intersection[0] : null
   }

   getCurrentBallIntersection(event) {
      if(!stateManager.getCurrentBall()) {
         return null
      }
      this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
	   this.pointer.y = (event.clientY / window.innerHeight) * -2 + 1;
      this.raycaster.setFromCamera(this.pointer, this.camera)
      const intersection = this.raycaster.intersectObject(stateManager.getCurrentBall().mesh)
      return intersection.length ? intersection[0] : null
   }

   getBoundedIntersectionPoint(intersection, currentBall) {
      const radius = currentBall.body.boundingRadius
      const minBoundWidth = this.width * -0.5 + radius + this.minDropGap
      const maxBoundWidth = this.width * 0.5 - radius - this.minDropGap
      const minBoundDepth = this.depth * -0.5 + radius  + this.minDropGap
      const maxBoundDepth = this.depth * 0.5 - radius - this.minDropGap
      let { point } = intersection
      point.x = Math.min(Math.max(point.x, minBoundWidth), maxBoundWidth)
      point.z = Math.min(Math.max(point.z, minBoundDepth), maxBoundDepth)
      return point
   }

   setBallAndLocatorPosition(point) {
      this.locatorBeacon.position.copy(point)
      this.locatorBeacon.position.y += 0.02
      this.locatorDropLine.position.x = this.locatorBeacon.position.x
      this.locatorDropLine.position.z = this.locatorBeacon.position.z
      stateManager.getCurrentBall().body.position.x = point.x
      stateManager.getCurrentBall().body.position.z = point.z
   }
}

