import stateManager from './state-manager'
import { mergeBalls, expandBall } from './animations'
import { RollbarLogger } from './rollbar-logger'

export const useEventHandlers = function() {
   
   stateManager.getCanvas().addEventListener('requestBallDrop', () => {
      if(stateManager.getCurrentBall() != null) {
         stateManager.getCurrentBall().drop()
         stateManager.unsetCurrentBall()
         stateManager.incrementTurn()
      }
   })
   stateManager.getCanvas().addEventListener('requestBallMerge', async ({ detail }) => {
      stateManager.incrementOngoingMerges()
      const ballFactory = stateManager.getBallFactory()
      const thisMesh = stateManager.getMeshByUUID(detail.thisBall._meshUUID)
      const thoseMeshes = detail.thoseBalls.map((b) => stateManager.getMeshByUUID(b._meshUUID))
      const impulseMagnitude = 2

      /**
       * Take the unit vector of the cross-product of each ball with the merge target.
       * Replace the y value with the y value of the unit vector of the result of subtraction.
       */
      const impulseVectors = detail.thoseBalls.map((b) => {
         const vsub = detail.thisBall.position.vsub(b.position).unit()
         const verticalImpulse = vsub.y
         const crossProduct = detail.thisBall.position.cross(b.position).unit()
         crossProduct.y = verticalImpulse
         return crossProduct
      })
      // Simply add all the vectors together to get their combined influence (if multimerge) 
      const impulseVector = impulseVectors.reduce((a, b) => a.vadd(b))

      impulseVector.x *= impulseMagnitude
      impulseVector.y *= impulseMagnitude
      impulseVector.z *= impulseMagnitude

      stateManager.addBodyToRemove(detail.thisBall)
      for(const b of detail.thoseBalls) {
         stateManager.addBodyToRemove(b)
      }

      const result = await mergeBalls(thisMesh, thoseMeshes)
      if(detail.thoseBalls.length > 1) {
         stateManager.getCanvas().dispatchEvent(new CustomEvent('multiMerge', {
            detail: {
               count: detail.thoseBalls.length + 1,
               location: detail.thisBall.position
            }
         }))
      }
      // Bail out if the game is resetting
      if(stateManager.state.isResetting) {
         stateManager.decrementOngoingMerges()
         return false
      }
      const newBall = ballFactory.makeBall(result.type + thoseMeshes.length, result.position, false, expandBall)
      stateManager.addBall(newBall)
      /**
       * To make merge chaining easier, we look for nearby matching bodies for the
       * newly-created ball right away. If we find any, we won't apply impulse to
       * the new ball. This gives it a better shot at merging.
       */
      const newMatchingBodies = ballFactory.getNearbyMatchingBodies(newBall.body, null)
      
      /**
       * Only apply impulse if there's no possible merge chain.
       * We don't want a potential merge chain to be ruined by a ball flying off
       * in the wrong direction.
       */
      if(!newMatchingBodies.length) {
         newBall.body.applyLocalImpulse(impulseVector, {x: 0, y: 0, z: 0})
      }
      
      const triPlusOne = [1,2,4,7,11,16,22,29,37,46,56]
      stateManager.incrementPoints(triPlusOne[result.type + thoseMeshes.length - 1])
   })
   stateManager.getCanvas().addEventListener('mergeComplete', ({ detail }) => {
      /** Let stateManager know that merge is complete */
      stateManager.decrementOngoingMerges()
      /** If there are no other ongoing merges, autosave the game */
      if(stateManager.getOngoingMerges() < 1) {
         stateManager.saveState()
      }
   })
   stateManager.getCanvas().addEventListener('ballCreated', async ({ detail }) => {
      if(detail.type > stateManager.getHighestBallSeen()) {
         stateManager.setHighestBallSeen(detail.type)
      }
   })
   stateManager.getCanvas().addEventListener('requestNewBall', async () => {
      stateManager.state.chooseBallTimeoutID = setTimeout(() => {
         if(!stateManager.gameIsSuspended()) {
            stateManager.addCurrentBall(
               stateManager.getBallFactory().makeBall(stateManager.chooseNewBall())
            )
         }
         stateManager.state.chooseBallTimeoutID = null
      }, stateManager.state.chooseBallTimeout)
   })
   window.addEventListener('requestDropModeDeactivation', () => {
      stateManager.deactivateDropMode()
   })
   window.addEventListener('gameOver', ({ detail }) => {
      console.log('losing ball', detail.losingBall)
      alert(`Game over! Your score: ${stateManager.getPoints()}`)
      RollbarLogger.logMeshBodyError(detail.losingBall)
      RollbarLogger.logEndGameError()
      stateManager.state.gameOver = true
      stateManager.getCanvas().dispatchEvent(new CustomEvent('requestGameHalt', { detail }))
   })
}