<template>
   <div class="ui-overlay">
      <div class="top-bar">
         <ScoreBox :initialPoints="stateManager.getPoints()"></ScoreBox>
         <NextUpBox></NextUpBox>
      </div>
      <NewGameButton 
         :initialGameOverState="stateManager.state.gameOver" 
         :resetGame="() => stateManager.resetGame()">
      </NewGameButton>
      <CancelDropButton></CancelDropButton>
   </div>
</template>

<script setup>
import { useHead } from '@unhead/vue'
import ScoreBox from '../components/game-ui/ScoreBox.vue'
import NextUpBox from '../components/game-ui/NextUpBox.vue'
import NewGameButton from '../components/game-ui/NewGameButton.vue'
import CancelDropButton from '../components/game-ui/CancelDropButton.vue'

import stateManager from '../../state-manager'

const headTitle = 'Threeka'
const headDescription = 'A game about planets.'
const headURL = `${window.location.href}`

useHead({
   title: headTitle,
    meta: [
      { name: 'description', content: headDescription },
      { property: 'og:title', content: headTitle },
      {
        property: 'og:url',
        content: headURL,
      },
      {
        property: 'og:description',
        content: headDescription,
      },
      {
        property: 'twitter:url',
        content: headURL,
      },
      {
        property: 'twitter:title',
        content: headTitle,
      },
      {
        property: 'twitter:description',
        content: headDescription,
      },
    ]
})
</script>

<style scoped>
.top-bar {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: space-evenly;
  justify-content: flex-end;
  gap: 20px;
  user-select: none;
}
</style>