import stateManager from "./state-manager"
import { Ball } from "./ball"


export class RollbarLogger {

   static logMeshBodyError(ball) {
      if(!ball.body.position.almostEquals(ball.mesh.position)) {
         Rollbar.error(
            `Mesh Body Sync Error: Losing ball mesh and body positions out of sync.`,
            Ball.deserializeBall(ball.serializeBall())
         )
      }
   }

   static logEndGameError() {
      for(const ball of stateManager.getBallsInPlay()) {
         let top = ball.body.position.y + ball.body.boundingRadius
         if(top >= stateManager.getDropWindowHeight()) {
            return true
         }
      }
      Rollbar.error(
         `End Game Error: No ball intersects or is above drop window.`,
         stateManager.getBallsInPlay()
      )
   }
   
   static logTextureLoadError(url) {
      Rollbar.error(
         `Texture could not be loaded`,
         url
      )
   }

   static logState(message = "Game state") {
      //This needs to be decycled first
      Rollbar.info(message, stateManager.state)
   }
}