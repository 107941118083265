<template>
   <button v-show="store.showMenuButton" :class="{'menu-button': true, activated}" @click="toggleMenu">
      <svg class="line top-line" width="50" height="27" viewBox="0 0 50 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="50" y2="1" stroke="white" stroke-width="3"/>
      </svg>
      <svg class="line bottom-line" width="50" height="27" viewBox="0 0 50 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="50" y2="1" stroke="white" stroke-width="3"/>
      </svg>
    </button>
    <nav :class="{ menu: true, activated }">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }" @click="activated = false" :class="{'router-link': true, 'current-page': $route.name == 'home'}">Home</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'how-to-play' }" @click="activated = false" :class="{'router-link': true, 'current-page': $route.name == 'how-to-play'}">How to Play</router-link>
        </li>
        <li>
         <router-link :to="{ name: 'learning-center' }" @click="activated = false" :class="{'router-link': true, 'current-page': $route.name == 'learning-center'}">Learning Center</router-link>
        </li>
        <li>
         <router-link :to="{ name: 'options' }" @click="activated = false" :class="{'router-link': true, 'current-page': $route.name == 'options'}">Options</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'title-screen' }" @click="activated = false" :class="{'router-link': true, 'current-page': $route.name == 'title-screen'}">Title Screen</router-link>
        </li>
      </ul>
      <!-- <button>Save game</button> -->
    </nav>
    <div class="backdrop" v-if="activated" @click="toggleMenu"></div>
</template>

<script setup>
import { ref } from 'vue'
import { store } from '../store'
import { RouterLink } from 'vue-router'

const activated = ref(false)

const toggleMenu = function() {
   activated.value = !activated.value
}

</script>

<style scoped>
.menu-button {
   position: absolute;
   top: 15px;
   left: 15px;
   display: flex;
   flex-direction: column;
   z-index: 4;
   transition: margin 0.3s ease;
   background-color: #00000000;
   border: none;
}
.menu-button .line {
   position: absolute;
   transform: scale(60%);
   transition: all 0.1s ease;
}
.menu-button .top-line {
   margin-top: -5px;
}
.menu-button .bottom-line {
   margin-top: 5px;
}
.line line {
   stroke: var(--text-color-primary);
}
.menu-button.activated {
   margin-left: calc(var(--sidebar-menu-width) - 65px);
}
.menu-button.activated .top-line {
   transform: rotate(45deg) scale(60%);
   margin-top: 0px;
}
.menu-button.activated .bottom-line {
   transform: rotate(-45deg) scale(60%);
   margin-top: 0px;
}

.menu {
   visibility: hidden;
   position: absolute;
   top: 0;
   left: calc(var(--sidebar-menu-width) * -1);
   width: 0vw;
   height: 100vh;
   z-index: 3;
}
.menu.activated {
   visibility: visible;
   display: block;
   left: 0vw;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: var(--sidebar-menu-width);
   height: 100vh;
   padding-top: 10vh;
   background-color: var(--bg-color-light);
   transition: left 0.3s ease;
}

.menu ul {
   list-style: none;
   font-size: 1.4rem;
}
.menu li {
   margin-bottom: 5px;
}
.router-link {
   text-decoration: none;
   color: var(--text-color-secondary);
}
.router-link.current-page {
   color: var(--text-color-primary);
}

.backdrop {
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vw;
   z-index: 2;
   background-color: #55555555
}
</style>