import * as CANNON from 'cannon-es'
import * as THREE from 'three'
import stateManager from './state-manager'

export class Ball {
   constructor(mesh, body, color) {
      this.body = body
      this.mesh = mesh
      this.color = color
      this.uuid = mesh.uuid
      this.isExpanding = false
      this.isNew = body.type == CANNON.Body.STATIC ? true : false

      stateManager.getCanvas().dispatchEvent(new CustomEvent('ballCreated', {
         detail: {
            type: body._ballType
         }
      }))
   }
   drop() {
      /**
       * Reset the collision filter mask to default when drop is called.
       * Balls are prevented from interacting with currentBall before it's dropped.
       */
      this.body.collisionFilterMask = -1
      // Zero out velocity just in case currentBall gets hit by another ball that bounced up
      this.body.velocity = new CANNON.Vec3(0, 0, 0)
      this.body.angularVelocity = new CANNON.Vec3(0, 0, 0)

      this.body.type = CANNON.Body.DYNAMIC
      this.body.applyLocalForce(new CANNON.Vec3(0, 1, 0), new CANNON.Vec3(0, 0, 0))
      
      setTimeout(() => {
         this.isNew = false
      }, 1000)

      stateManager.getCanvas().dispatchEvent(new Event('requestNewBall'))
   }

   static serializeBody(body) {
      let topLevel = true
      const s = JSON.stringify(body, (key, val) => {
         if(val != null && typeof val == "object") {
            if(topLevel) {
               topLevel = false
            }
            else if(val.hasOwnProperty('_isContainer') || val.hasOwnProperty('_ballType')){
               return `REPLACE_WITH_SELF`
            }
            else if(val.hasOwnProperty('broadphase')) {
               return `REPLACE_WITH_WORLD`
            }
         }
         return val
      })
      return s
   }
   static deserializeBody(str) {
      const obj = JSON.parse(str)
      const recursiveReplace = (o) => {
         for(const prop in o) {
            if(o[prop] == 'REPLACE_WITH_SELF') {
               o[prop] = o
            }
            else if(o[prop] == 'REPLACE_WITH_WORLD') {
               o[prop] = stateManager.getWorld()
            }
            else if( typeof o[prop] == 'object') {
               o[prop] = recursiveReplace(o[prop])
            }
         }
         return o
      }
      return recursiveReplace(obj)
   }
   /**
    * Take a mesh and serialize it using Object3D.toJSON.
    * We manually tack on the ball type, which will be how the deserializer 
    * knows which geometries and materials this mesh should have.
    * We also replace the base64 representation of the texture with a string path.
    * These modifications allows us to massively save on the size of the serialized
    * object, since we don't need to serialize geometries, materials, or images.
    * 
    * @param {THREE.Mesh} mesh The mesh to be serialized
    * @returns { Object } A serialized mesh
    */
   static serializeMesh(mesh) {
      const json = mesh.toJSON()
      json.metadata.ballType = mesh._ballType
      json.images[0].url = `/textures/planets/threeka-texture-ball_${mesh._ballType}.jpg`
      return JSON.stringify(json)
   }
   static async deserializeMesh(str) {
      const obj = JSON.parse(str)
      const geometryUUID = stateManager.getBallFactory().geometries[obj.metadata.ballType].uuid
      const material = stateManager.getBallFactory().materials[obj.metadata.ballType]
      obj.geometries[0].uuid = geometryUUID
      obj.materials[0].uuid = material.uuid
      obj.materials[0].map = material.map.uuid
      obj.textures[0].uuid = material.map.uuid
      obj.textures[0].image = material.map.source.uuid
      obj.images[0].uuid = material.map.source.uuid
      obj.object.geometry = geometryUUID
      obj.object.material = material.uuid
      const loader = new THREE.ObjectLoader()
      const loadingComplete = new Promise((resolve, reject) => {
         try {
            loader.parse(obj, (result) => {
               resolve(result)
            })
         } catch(e) {
            console.log('Could not load deserialized Mesh', e)
            reject(e)
         }
      })
      return await loadingComplete
   }
   serializeBall() {
      return JSON.stringify({
         mesh: Ball.serializeMesh(this.mesh),
         body: Ball.serializeBody(this.body),
         color: JSON.stringify(this.color),
         uuid: JSON.stringify(this.uuid),
         isExpanding: JSON.stringify(this.isExpanding),
         isNew: JSON.stringify(this.isNew)
      })
   }
   static async deserializeBall(str) {
      const obj = JSON.parse(str)
      const mesh = await Ball.deserializeMesh(obj.mesh)
      const body = Ball.deserializeBody(obj.body)
      const color = JSON.parse(obj.color)
      return new Ball(mesh, body, color)
   }
}