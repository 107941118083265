import * as THREE from 'three'
import { world, containerMaterial } from './physics'
import * as CANNON from 'cannon-es'

export const makeContainer = function(boxWidth, boxDepth, boxHeight) {
   const sidePlane = new THREE.PlaneGeometry(boxDepth, boxHeight)
   const frontAndBackPlane = new THREE.PlaneGeometry(boxWidth, boxHeight)
   const bottomPlane = new THREE.PlaneGeometry(boxWidth, boxDepth)
   const textureLoader = new THREE.TextureLoader()
   const glassTexture = textureLoader.load('/textures/threeka-glass-texture.png')
   glassTexture.colorSpace = THREE.SRGBColorSpace

   const material = new THREE.MeshStandardMaterial({
      color: 0xbffffe,
      transparent: true,
      opacity: 0.15,
      side: THREE.DoubleSide,
      wireframe: false,
      lightMap: glassTexture,
   })
   const bottomMaterial = new THREE.MeshStandardMaterial({
      color: 0x77fffc,
      transparent: true,
      opacity: 0.25,
      side: THREE.DoubleSide,
      wireframe: false,
   })

   const group = new THREE.Group()
   
   const backMesh = new THREE.Mesh(frontAndBackPlane, material)
   const frontMesh = backMesh.clone()
   const leftMesh = new THREE.Mesh(sidePlane, material)
   const rightMesh = leftMesh.clone()

   backMesh.position.set(0, 0, boxDepth * -0.5)
   frontMesh.position.set(0, 0, boxDepth * 0.5)
   frontMesh.rotateY(Math.PI)
   leftMesh.position.set(boxWidth * -0.5, 0, 0)
   leftMesh.rotateY(Math.PI * 0.5)
   rightMesh.position.set(boxWidth * 0.5, 0, 0)
   rightMesh.rotateY(Math.PI * -0.5)
   
   const bottomMesh = new THREE.Mesh(bottomPlane, bottomMaterial)
   bottomMesh.position.set(0, boxHeight * -0.5, 0)
   bottomMesh.rotateX(Math.PI * -0.5)
   bottomMesh.name = 'bottomMesh'

   bottomMesh.receiveShadow = true

   backMesh.name = 'backMesh'
   frontMesh.name = 'frontMesh'
   leftMesh.name = 'leftMesh'
   rightMesh.name = 'rightMesh'

   group.add(backMesh, frontMesh, leftMesh, rightMesh, bottomMesh)

   const planeShape = new CANNON.Plane()
   
   const backBody = new CANNON.Body({ type: CANNON.Body.STATIC, mass: 0, shape: planeShape })
   backBody.position.copy(group.getObjectByName('backMesh').position)
   backBody.quaternion.copy(group.getObjectByName('backMesh').quaternion)
   world.addBody(backBody)
   
   const frontBody = new CANNON.Body({ type: CANNON.Body.STATIC, mass: 0, shape: planeShape })
   frontBody.position.copy(group.getObjectByName('frontMesh').position)
   frontBody.quaternion.copy(group.getObjectByName('frontMesh').quaternion)
   world.addBody(frontBody)
   
   const leftBody = new CANNON.Body({ type: CANNON.Body.STATIC, mass: 0, shape: planeShape })
   leftBody.position.copy(group.getObjectByName('leftMesh').position)
   leftBody.quaternion.copy(group.getObjectByName('leftMesh').quaternion)
   world.addBody(leftBody)
   
   const rightBody = new CANNON.Body({ type: CANNON.Body.STATIC, mass: 0, shape: planeShape })
   rightBody.position.copy(group.getObjectByName('rightMesh').position)
   rightBody.quaternion.copy(group.getObjectByName('rightMesh').quaternion)
   world.addBody(rightBody)

   const bottomBody = new CANNON.Body({ type: CANNON.Body.STATIC, mass: 0, shape: planeShape })
   bottomBody.position.copy(bottomMesh.position)
   bottomBody.quaternion.copy(bottomMesh.quaternion)
   world.addBody(bottomBody)

   const bodies = { frontBody, backBody, leftBody, rightBody, bottomBody }
   
   for (const body in bodies) {
      bodies[body]._isContainer = true
      bodies[body].material = containerMaterial
   }

   const container = {
      mesh: group,
      bodies
   }
   return container
}