import stateManager from "./state-manager"
import { world } from './physics'
import { SAPBroadphase } from 'cannon-es'
import { RollbarLogger } from "./rollbar-logger"

export class EndGameManager {
   constructor() {
      // Balls to position-check after timer expires. Has the shape: [{ ball, checkTime }, ...]
      this.scheduledBalls = []
      this.currentlyMerging = []
      this.speedThreshold = 0.9
      this.countdownTimer = 2.5
      this.forgivenessDistance = 0.15

      stateManager.getCanvas().addEventListener('requestBallMerge', async ({ detail }) => {
         this.unscheduleBallByUUID(detail.thisBall._meshUUID)
         for(const b of detail.thoseBalls) {
            this.unscheduleBallByUUID(b._meshUUID)
         }
      })
   }

   ballOutOfBounds(ball) {
      const topOfBall = ball.body.position.y + ball.body.boundingRadius
      return topOfBall > stateManager.getDropWindowHeight() + this.forgivenessDistance
   }

   ballHasContacts(ball) {
      const bodies = world.bodies.filter(b => !b._isContainer && b.id != ball.body.id)
         for (const b of bodies) {
            if(SAPBroadphase.boundingSphereCheck(ball.body, b)) {
               return true
            }
         }
      return false
   }

   scheduleBall(ball, elapsedTime) {
      this.scheduledBalls.push({
         ball,
         checkTime: elapsedTime + this.countdownTimer
      })
   }

   unscheduleBall(ball) {
      this.scheduledBalls = this.scheduledBalls.filter(el => el.ball.mesh.uuid != ball.mesh.uuid)
   }

   unscheduleBallByUUID(uuid) {
      this.scheduledBalls = this.scheduledBalls.filter(el => el.ball.mesh.uuid != uuid)
   }

   isScheduled(ball) {
      return this.scheduledBalls.some(el => el.ball.mesh.uuid == ball.mesh.uuid)
   }

   /**
    * 
    * @param {Object} ball 
    * @returns Whether the ball is moving faster in any direction than 0.9 m/s
    */
   isMovingSlowly(ball) {
      const threshold = this.speedThreshold
      const xSpeed = Math.abs(ball.body.velocity.x)
      const ySpeed = Math.abs(ball.body.velocity.y)
      const zSpeed = Math.abs(ball.body.velocity.z)
      if(xSpeed > threshold || ySpeed > threshold || zSpeed > threshold) {
         return false
      }
      return true
   }

   testEndGame(elapsedTime) {
      if(stateManager.gameIsSuspended()) {
         return false
      }
      const ballsInPlay = stateManager.getBallsInPlay()
      // If any ball is currently merging, hold off on ending the game until it finishes.
      for(const ball of ballsInPlay) {
         if(ball.isExpanding) {
            return false
         }
      }
      for(const { ball, checkTime } of this.scheduledBalls) {
         // If the ball is moving faster than a certain threshold, unschedule it
         if(!this.isMovingSlowly(ball)) {
            this.unscheduleBall(ball)
         }
         else if(elapsedTime >= checkTime) {
            if(this.ballOutOfBounds(ball)) {
               // If the ball is OOB, but not touching anything, just let it fall
               if(!this.ballHasContacts(ball)) {
                  this.unscheduleBall(ball)
                  return false
               }
               this.scheduledBalls = []
               window.dispatchEvent(new CustomEvent('gameOver', {
                  detail: {
                     losingBall: ball
                  }
               }))
               return true
            }
            else {
               this.unscheduleBall(ball)
            }
         }
      }
      for(const ball of ballsInPlay) {
         if(!this.isScheduled(ball) && this.ballOutOfBounds(ball) && !ball.isNew) {
            this.scheduleBall(ball, elapsedTime)
         }
      }
      return false
   }
}