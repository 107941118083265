<template>
   <button @click="reset" :class="{'new-game-button': true, visible: gameOver}">New game</button>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
   initialGameOverState: Boolean,
   resetGame: Function
})

const gameOver = ref(props.initialGameOverState)

const reset = function() {
   props.resetGame()
   gameOver.value = false
}

window.addEventListener('gameOver', () => {
   gameOver.value = true
})
</script>

<style scoped>
.new-game-button {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding: 4px 10px;
  font-size: 30px;
  background-color: green;
  color: lightgreen;
  border: 2px solid rgb(2, 50, 2);
  border-radius: 10px;
  user-select: none;
}
.new-game-button.visible {
  display: block;
}
.new-game-button:hover {
  border: 2px solid var(--text-color-primary);
}
.new-game-button:active {
  margin-top: 12px;
  margin-bottom: 8px;
}
</style>