import * as THREE from 'three'
import stateManager from './state-manager'
import { store } from './vue/store'

class AudioManager {
   constructor() {
      this.musicIsPlaying = false
      this.listener = this.initListener()
      this.loader = new THREE.AudioLoader()
      this.music = new Audio('/audio/bouncy-sparse.mp3')
      this.music.loop = true
      this.updateMusicVolume()
      this.music.addEventListener('canplaythrough', () => {
         store.audioLoaded = true
      })
      this.music.load()
      this.mergeSound = new THREE.Audio(this.listener)

      window.addEventListener('visibilitychange', () => {
         /**
          * gameInProgress is set to true when the player begins a game.
          * This is a gesture which allows browsers to play audio.
          */
         if(!store.gameInProgress) {
            return false
         }
         if(document.hidden) {
            this.pauseMusic()
         }
         else {
            this.playMusic()
         }
      })
   }

   initListener() {
      const listener = new THREE.AudioListener
      stateManager.getCamera().add(listener)
      return listener
   }
   loadMergeSound(path) {
      this.loader.load(path, (buffer) => {
         this.mergeSound.setBuffer(buffer)
         this.mergeSound.setVolume(store.effectsVolume / 10)
      })
   }
   playMusic() {
      this.music.play()
      this.musicIsPlaying = true
   }
   pauseMusic() {
      this.music.pause()
      this.musicIsPlaying = false
   }
   stopMusic() {
      this.music.stop()
      this.musicIsPlaying = false
   }
   updateMusicVolume() {
      this.music.volume = store.musicVolume / 10
   }
   playMergeSound() {
      this.mergeSound.play()
   }
   updateEffectsVolume() {
      this.mergeSound.setVolume(store.effectsVolume / 10)
   }
}

const audioManager = new AudioManager()
audioManager.loadMergeSound('/audio/low-short-pop.mp3')

export { audioManager }