<template>
   <div class="loading-screen">
      <h1 class="header">Loading...</h1>
      <img src="/gifs/planet_0.gif" class="loading-spinner">
   </div>
</template>

<script setup>
   
</script>

<style scoped>
.loading-screen {
   position: absolute;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100vh;
   background-color: var(--bg-color-dark);
   z-index: 5;
}
.loading-spinner {
   position: fixed;
   bottom: 10px;
   right: 10px;
   width: 50px;
   height: 50px;
}
.header {
   margin-top: -15vh;
   opacity: 1;
   z-index: 6;
}
</style>