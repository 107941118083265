import * as CANNON from 'cannon-es'

/**
 * Contact between a ball and the container uses containerContactMaterial.
 * Contact between two balls uses defaultContactMaterial.
 */

const containerMaterial = new CANNON.Material('container')
const ballMaterial = new CANNON.Material('ball')

const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
   defaultMaterial,
   defaultMaterial,
   {
       friction: 0.2,
       restitution: 0.25,
   }
)

const containerContactMaterial = new CANNON.ContactMaterial(
   containerMaterial,
   ballMaterial,
   {
      friction: 0.3,
      restitution: 0.25
   }
)


const gravityScalar = 1.25

const world = new CANNON.World({
   gravity: new CANNON.Vec3(0, -9.82 * gravityScalar, 0),
   allowSleep: false,
})

world.broadphase = new CANNON.SAPBroadphase(world)
world.defaultMaterial = defaultMaterial
world.defaultContactMaterial = defaultContactMaterial
world.addContactMaterial(containerContactMaterial)

export { world, containerMaterial, ballMaterial }