import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import GUI from 'lil-gui'
import BallFactory from './ball-factory'
import { world } from './physics'
import stateManager from './state-manager'
import { makeContainer } from './container'
import { useEventHandlers } from './event-handlers'
import { DropWindow } from './drop-window'
import { EndGameManager } from './end-game'
import { RollbarLogger } from './rollbar-logger'
import { Ball } from './ball'
import { store } from './vue/store'
// import Stats from 'three/addons/libs/stats.module.js'
// import { CannonDebugRenderer } from './cannon-debug-renderer'

const scene = stateManager.getScene()
const canvas = stateManager.getCanvas()
const renderer = stateManager.getRenderer()
const camera = stateManager.getCamera()

// const stats = new Stats()
// stats.dom.style.top = 'unset'
// stats.dom.style.bottom = '0px'
// document.querySelector('body').appendChild(stats.dom)

// const gui = new GUI({ container: document.querySelector('.lil-gui-container') })
// gui.add(world.defaultContactMaterial, 'friction').min(0).max(1).step(0.01)
// gui.add(world.defaultContactMaterial, 'restitution').min(0).max(1).step(0.01).name('bounciness')

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxDistance = 80
controls.minDistance = 30
controls.maxPolarAngle = Math.PI - 0.2
controls.minPolarAngle = 0.2
controls.mouseButtons = {
	LEFT: THREE.MOUSE.ROTATE,
	MIDDLE: THREE.MOUSE.DOLLY,
	RIGHT: THREE.MOUSE.ROTATE
}

stateManager.setOrbitControls(controls)

const boxWidth = 10
const boxHeight = 15
const boxDepth = 10

stateManager.setBoxDimensions(boxWidth, boxHeight, boxDepth)

const ambientLight = new THREE.AmbientLight(0xffffff, 1.2)
const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1)
directionalLight1.position.set(30, 30, 30)
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1)
directionalLight2.position.set(30, 30, -30)
const shadowCastingLight = new THREE.DirectionalLight(0xffffff, 2)
shadowCastingLight.position.set(boxWidth * 0.5, boxHeight * 0.5, 0)
shadowCastingLight.target.position.set(0, boxHeight * -0.5, 0)
shadowCastingLight.castShadow = store.renderShadows
scene.add(ambientLight, directionalLight1, directionalLight1.target, directionalLight2, directionalLight2.target, shadowCastingLight, shadowCastingLight.target)

const container = makeContainer(boxWidth, boxDepth, boxHeight)
scene.add(container.mesh)

const dropWindow = new DropWindow(scene, camera, boxWidth, boxDepth, boxHeight)
stateManager.setDropWindow(dropWindow)

const initBallFactory = async function() { 
  return new Promise((resolve, reject) => {
    const loadingManager = new THREE.LoadingManager()
    const textureLoader = new THREE.TextureLoader(loadingManager)
    const textureURLs = [
      { name: 'colorTexture00', path: '/textures/planets/threeka-texture-ball_0.jpg' },
      { name: 'colorTexture01', path: '/textures/planets/threeka-texture-ball_1.jpg' },
      { name: 'colorTexture02', path: '/textures/planets/threeka-texture-ball_2.jpg' },
      { name: 'colorTexture03', path: '/textures/planets/threeka-texture-ball_3.jpg' },
      { name: 'colorTexture04', path: '/textures/planets/threeka-texture-ball_4.jpg' },
      { name: 'colorTexture05', path: '/textures/planets/threeka-texture-ball_5.jpg' },
      { name: 'colorTexture06', path: '/textures/planets/threeka-texture-ball_6.jpg' },
      { name: 'colorTexture07', path: '/textures/planets/threeka-texture-ball_7.jpg' },
      { name: 'colorTexture08', path: '/textures/planets/threeka-texture-ball_8.jpg' },
      { name: 'colorTexture09', path: '/textures/planets/threeka-texture-ball_9.jpg' },
      { name: 'colorTexture10', path: '/textures/planets/threeka-texture-ball_10.jpg' },
      { name: 'colorTexture11', path: '/textures/planets/threeka-texture-ball_11.jpg' },
    ]
    const textures = {}
    textureURLs.forEach(({name, path}) => {
      const texture = textureLoader.load(path)
      texture.colorSpace = THREE.SRGBColorSpace
      textures[name] = texture
    })
    loadingManager.onLoad = () => {
      stateManager.setBallFactory(new BallFactory(scene, textures))
      resolve(stateManager.getBallFactory())
      store.texturesLoaded = true
    }
    loadingManager.onError = (url) => {
      reject(`Texture could not be loaded: ${url}`)
      console.log(`Texture could not be loaded: ${url}`)
      RollbarLogger.logTextureLoadError(url)
    }
  })
}

initBallFactory().then(() => {
  const firstBall = stateManager.getBallFactory().makeBall(stateManager.chooseNewBall())
  stateManager.addCurrentBall(firstBall)
  useEventHandlers(stateManager.getBallFactory())
  /** Start the animation loop after textures are loaded */
  tick()
})

// const cannonDebugRenderer = new CannonDebugRenderer(scene, world)
// gui.add(cannonDebugRenderer._material, 'transparent').onChange((val) =>{
//   if(val) {
//     cannonDebugRenderer._material.opacity = 0
//   }
//   else {
//     cannonDebugRenderer._material.opacity = 1
//   }
// })

const endGameManager = new EndGameManager()
stateManager.setEndGameManager(endGameManager)

stateManager.getCanvas().addEventListener('requestGameHalt', () => {
  stateManager.suspendGame()
  const ball = stateManager.getCurrentBall()
  if(ball) {
    stateManager.unsetCurrentBall()
    world.removeBody(ball.body)
    scene.remove(ball.mesh)
  }
  for(const b of stateManager.getBalls()) {
    world.removeBody(b.body)
  }
})

window.addEventListener('settingsChangeShadows', () => {
  shadowCastingLight.castShadow = store.renderShadows
})

const debugObject = {
  mockBall: (size = 10) => {
    const ball = stateManager.getBallFactory().makeBall(size, new THREE.Vector3(0, 20, 0), false, null)
    // ball.body.applyTorque(new THREE.Vector3((Math.random() - 0.5) * 120, (Math.random() - 0.5) * 120, (Math.random() - 0.5) * 120))
    stateManager.addBall(ball)
  },
  logCurrentBall: () => {
    console.log('currentBall', Ball.serialize(stateManager.getCurrentBall().body))
  },
  zeroPosition: () => {
    stateManager.getCurrentBall().body.position.set(0, stateManager.getBallDropHeight(), 0)
  }
}
// gui.add(debugObject, 'mockBall')
// gui.add(debugObject, 'logCurrentBall')
// gui.add(debugObject, 'zeroPosition')
window.addEventListener('keyup', async (event) => {
  if(event.code == 'Enter') {
    debugObject.mockBall()
  }
  if(event.code.includes('Digit')) {
    debugObject.mockBall(parseInt(event.code.split('t')[1]))
  }
  if(event.code == 'KeyZ') {
    debugObject.zeroPosition()
  }
  if(event.code == 'KeyS') {
    const savedState = await stateManager.saveState()
    console.log('savedState', savedState)
  }
  if(event.code == 'KeyL') {
    stateManager.loadSave()
  }
  if(event.code == 'KeyD') {
    const json = scene.toJSON()
    console.log('scene.toJSON()', json)
  }
  if(event.code == 'KeyC') {
    stateManager.clearSave()
  }
  if(event.code == 'KeyP') {
    console.log('game save', stateManager.retrieveFromStorage('threekagamesave'))
  }
  if(event.code == 'KeyR') {
    stateManager.resetGame()
  }
  if(event.code == 'KeyQ') {
    localStorage.setItem('threekagamesave', JSON.stringify({
      "points": 14,
      "turn": 10,
      "inPlay": [
        {
          "type": 2,
          "position": {
            "x": -2.1401083769842955,
            "y": -6.350003084090669,
            "z": -2.1268130132244605
          },
          "isStatic": false,
          "expandFunction": null,
          "impulseVector": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "quaternion": {
            "x": 0,
            "y": -0.7071067811865476,
            "z": 0,
            "w": 0.7071067811865476
          },
          "velocity": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        },
        {
          "type": 3,
          "position": {
            "x": 0.4118105167442402,
            "y": -6.100003084090669,
            "z": -1.9859291177735092
          },
          "isStatic": false,
          "expandFunction": null,
          "impulseVector": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "quaternion": {
            "x": -0.31126112278933443,
            "y": -0.5020701537031778,
            "z": -0.2847204757701083,
            "w": 0.7549677641314454
          },
          "velocity": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        },
        {
          "type": 1,
          "position": {
            "x": -0.4922146245630591,
            "y": -6.600003084090669,
            "z": 0.515928463633322
          },
          "isStatic": false,
          "expandFunction": null,
          "impulseVector": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "quaternion": {
            "x": 0.26300807971817314,
            "y": -0.779802966345481,
            "z": -0.40758978978788524,
            "w": 0.39573305010122084
          },
          "velocity": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        },
        {
          "type": 4,
          "position": {
            "x": 2.3809260143816875,
            "y": -5.850003084090669,
            "z": 0.39054523260812485
          },
          "isStatic": false,
          "expandFunction": null,
          "impulseVector": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "quaternion": {
            "x": -0.21508375166906596,
            "y": -0.897108641906063,
            "z": -0.19322144343132838,
            "w": 0.33406666727426293
          },
          "velocity": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        },
        {
          "type": 1,
          "position": {
            "x": -2.3496047774586164,
            "y": -6.600003084090669,
            "z": -0.06576419297685696
          },
          "isStatic": false,
          "expandFunction": null,
          "impulseVector": {
            "x": 0,
            "y": 0,
            "z": 0
          },
          "quaternion": {
            "x": 0,
            "y": -0.7071067811865476,
            "z": 0,
            "w": 0.7071067811865476
          },
          "velocity": {
            "x": 0,
            "y": 0,
            "z": 0
          }
        }
      ],
      "current": 0,
      "nextBalls": [
        0,
        0
      ],
      "highestBallSeen": 4
    }))
    stateManager.loadSave()
  }
  if(event.code == 'KeyF') {
    const json = scene.toJSON()
    scene.clear()
    loader.parse(json, (parsed) => scene.add(parsed))
  }
  if(event.code == 'KeyM') {
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(-2.7, -5.5, -2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(2.7, -5.5, -2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(2.7, -5.5, 2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(-2.7, -5.5, 2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(0, -2.8, 0), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(2.7, -0.5, 2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(-2.7, -0.5, -2.7), false, null))
    stateManager.addBall(stateManager.getBallFactory().makeBall(6, new THREE.Vector3(2.7, -0.5, -2.7), false, null))
  }
})

const clock = new THREE.Clock()
let previousTime = 0
const deltaScalar = stateManager.state.deltaScalar

const tick = () =>
{
    // stats.begin()
    // Alternate the SAPBroadphase axisIndex between 0 and 1
    world.broadphase.axisIndex = world.broadphase.axisIndex ^ 1
    // Alternate the SAPBroadphase axisIndex between 0, 1, and 2 (x, y, and z)
    // world.broadphase.axisIndex = (world.broadphase.axisIndex + 1) % 3
    const elapsedTime = clock.getElapsedTime()
    const delta = elapsedTime - previousTime
    previousTime = elapsedTime

    world.step(1/60, delta * deltaScalar, 10)

    for (const ball of stateManager.getBalls()) {
      ball.mesh.position.copy(ball.body.position)
      ball.mesh.quaternion.copy(ball.body.quaternion)
    }

    endGameManager.testEndGame(elapsedTime)

    controls.update()
    camera.lookAt(container.mesh.position)

    // cannonDebugRenderer.update()

    renderer.render(scene, camera)

    
    stateManager.removeScheduledBodies()
    stateManager.updateBalls()

    window.requestAnimationFrame(tick)
    // stats.end()
  }