import { gsap } from 'gsap'
import stateManager from './state-manager'
import { audioManager } from './audio'

const expandStartRadiusScalar = 0.8

const removeMeshes = function(meshes) {
   const scene = stateManager.getScene()
   for(const mesh of meshes) {
      scene.remove(mesh)
   }
}

export const mergeBalls = async function(thisMesh, thoseMeshes) {
   const position = thisMesh.position
   const type = thisMesh._ballType
   const duration = 0.25
   const thisMeshScalar = expandStartRadiusScalar
   const thoseMeshesScalar = 0
   const rotation = Math.PI * 2
   const tl = gsap.timeline({repeat: 0,
      onComplete: () => {
         removeMeshes(thoseMeshes.concat(thisMesh))
      }
   })

   tl.to(
      thisMesh.scale, {
         duration,
         delay: 0,
         ease: 'power2.inOut',
         x: thisMeshScalar,
         y: thisMeshScalar,
         z: thisMeshScalar
      },
      '<'
   )
   tl.to(
      thisMesh.rotation, {
         duration,
         delay: 0,
         ease: 'power2.inOut',
         x: rotation,
         y: rotation,
         z: rotation
      },
      '<'
   )

   thoseMeshes.map((mesh) => {
      tl.to(
         mesh.position,
         {
            duration,
            delay: 0,
            ease: 'power2.inOut',
            x: thisMesh.position.x,
            y: thisMesh.position.y,
            z: thisMesh.position.z,
            onComplete: () => {
               return 'success'
            }
         },
         // This tells the gsap timeline that the animations are simultaneous
         '<'
      )
      tl.to(
         mesh.scale, {
            duration,
            delay: 0,
            ease: 'power2.inOut',
            x: thoseMeshesScalar,
            y: thoseMeshesScalar,
            z: thoseMeshesScalar
         },
         '<'
      )
      tl.to(
         mesh.rotation, {
            duration,
            delay: 0,
            ease: 'power2.inOut',
            x: rotation,
            y: rotation,
            z: rotation
         },
         '<'
      )
   })
   return new Promise(resolve => {
      setTimeout(() => {
         resolve({
            position,
            type
         })
      }, duration * 1000)
   })
}

export const expandBall = async function(ball, fromRadius, toRadius) {
   const scalar = toRadius / fromRadius
   const duration = 0.35
   ball.isExpanding = true
   // Ensure that the ball's properties continuously update throughout the animation
   stateManager.scheduleUpdate(ball)

   const tl = gsap.timeline({repeat: 0,
      onComplete: () => {
         ball.isExpanding = false
         stateManager.unscheduleUpdate(ball)
         stateManager.getCanvas().dispatchEvent(new CustomEvent('mergeComplete', {
            detail: {
               ball
            }
         }))
      },
      onStart: () => {
         audioManager.playMergeSound()
      }
   })
   tl.to(
      ball.body,
      {
         duration,
         delay: 0,
         ease: 'power2.inOut',
         boundingRadius: fromRadius * scalar,
         // It is vital that the underlying shape is also updated, or else there'll be clipping
         onUpdate: () => ball.body.shapes[0].radius = ball.body.boundingRadius
      }, 
      0
   )
   tl.to(
      ball.mesh.scale,
      {
         duration,
         delay: 0,
         ease: 'power2.inOut',
         x: scalar,
         y: scalar,
         z: scalar,
      },
      0
   )
}