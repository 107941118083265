import { scene, canvas, renderer, camera } from './scene'
import { world } from "./physics"

class StateManager {
   constructor() {
      this.state = {
         scene,
         canvas,
         renderer,
         camera,
         world,
         ballFactory: null,
         dropWindow: null,
         endGameManager: null,
         ballDropHeight: 10,
         balls: [],
         bodiesToRemove: [],
         needsUpdate: [],
         currentBall: null,
         points: 0,
         orbitControls: null,
         boxDimensions: { x: 10, y: 15, z: 10 },
         dropModeActive: false,
         turn: 0,
         highestBallSeen: 0,
         ballSelection: [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3], [0, 1, 2, 3, 4]],
         nextBalls: [0, 0],
         chooseBallTimeout: 500,
         chooseBallTimeoutID: null,
         gameSuspended: false,
         gameOver: false,
         // Physics simulation speed
         deltaScalar: 2.3,
         /**
          * Distance within which two like balls are considered to be touching. 
          * Used in multimerge and merge chain checking.
          */
         nearbyMatchDistance: 0.18,
         // Flag set on a timeout when game resets, to prevent ball merges during this time
         isResetting: false,
         // The number of currently ongoing ball merges. Keeping track of this for state save lockout.
         ongoingMerges: 0
      }
   }
   getScene() {
      return this.state.scene
   }
   getCanvas() {
      return this.state.canvas
   }
   getRenderer() {
      return this.state.renderer
   }
   getCamera() {
      return this.state.camera
   }
   getWorld() {
      return this.state.world
   }
   getMeshByUUID(uuid) {
      return this.getScene().children.find(mesh => mesh.uuid == uuid)
   }
   getBallByUUID(uuid) {
      return this.getBalls().find(b => b.uuid = uuid)
   }
   setBallFactory(ballFactory) {
      this.state.ballFactory = ballFactory
   }
   getBallFactory() {
      return this.state.ballFactory
   }
   setDropWindow(dropWindow) {
      this.state.dropWindow = dropWindow
   }
   getDropWindow() {
      return this.state.dropWindow
   }
   setEndGameManager(endGameManager) {
      this.state.endGameManager = endGameManager
   }
   getEndgameManager() {
      return this.state.endGameManager
   }
   getBallDropHeight() {
      return this.state.ballDropHeight
   }
   getBalls() {
      return this.state.balls
   }
   setBalls(balls) {
      this.state.balls = balls
   }
   addBall(ball) {
      this.state.balls.push(ball)
   }
   #setCurrentBall(ball) {
      this.state.currentBall = ball
   }
   unsetCurrentBall() {
      this.state.currentBall = null
   }
   getCurrentBall() {
      return this.state.currentBall
   }
   addCurrentBall(ball) {
      this.#setCurrentBall(ball)
      this.addBall(ball)
      canvas.dispatchEvent(new Event('ballAvailableToDrop'))
   }
   #removeBallFromState(uuid) {
      this.state.balls = this.getBalls().filter((ball) => ball.uuid != uuid)
      this.getEndgameManager().unscheduleBallByUUID(uuid)
   }
   #disposeCurrentBall() {
      if(this.getCurrentBall()) {
         world.removeBody(this.getCurrentBall().body)
         scene.remove(this.getCurrentBall().mesh)
         this.#removeBallFromState(this.getCurrentBall().uuid)
         this.unsetCurrentBall()
      }
   }
   replaceCurrentBall(ball) {
      this.#disposeCurrentBall()
      this.addCurrentBall(ball)
   }
   getBallsInPlay() {
      return this.getBalls().filter((ball) => ball.body.id != this.getCurrentBall()?.body.id)
   }
   getBodiesToRemove() {
      return this.state.bodiesToRemove
   }
   getBallsNeedingUpdate() {
      return this.state.needsUpdate
   }
   /**
    * 
    * @param {Object} body A Cannon body. The associated Mesh is accessible by lookup of the ._meshUUID property.
    */
   addBodyToRemove(body) {
      this.state.bodiesToRemove.push(body)
   }
   scheduleUpdate(ball) {
      this.state.needsUpdate.push(ball)
   }
   unscheduleUpdate(ball) {
      this.state.needsUpdate = this.getBallsNeedingUpdate().filter((b) => b.body.id != ball.body.id)
   }
   removeScheduledBodies() {
      while(this.state.bodiesToRemove.length > 0) {
         const body = this.state.bodiesToRemove.pop()
         this.#removeBallFromState(body._meshUUID)
         world.removeBody(body)
      }
   }
   /**
    * Update ball body properties during merge.
    */
   updateBalls() {
      for (const ball of this.getBallsNeedingUpdate()) {
         ball.body.updateBoundingRadius()
         ball.body.updateMassProperties()
      }
   }
   getPoints() {
      return this.state.points
   }
   incrementPoints(amount) {
      this.state.points += amount
      window.dispatchEvent(new CustomEvent('scoreUpdated', {
         detail: {
            points: this.getPoints()
         }
      }))
   }
   zeroPoints() {
      this.state.points = 0
      window.dispatchEvent(new CustomEvent('scoreUpdated', {
         detail: {
            points: this.getPoints()
         }
      }))
   }
   setOrbitControls(controls) {
      this.state.orbitControls = controls
   }
   getBoxDimensions() {
      return this.state.boxDimensions
   }
   setBoxDimensions(x, y, z) {
      this.state.boxDimensions = { x, y, z }
   }
   getDropWindowHeight() {
      return this.getBoxDimensions().y * 0.5
   }
   getdropModeStatus() {
      return this.state.dropModeActive
   }
   activateDropMode(pointerType) {
      this.state.dropModeActive = true
      this.state.orbitControls.enabled = false
      if(pointerType != "mouse") {
         window.dispatchEvent(new Event('requestCancelButtonVisibilityOn'))
      }
   }
   deactivateDropMode() {
      this.state.dropModeActive = false
      this.state.orbitControls.enabled = true
      window.dispatchEvent(new Event('requestCancelButtonVisibilityOff'))
   }
   getTurn() {
      return this.state.turn
   }
   incrementTurn() {
      this.state.turn += 1
   }
   getOngoingMerges() {
      return this.state.ongoingMerges
   }
   incrementOngoingMerges() {
      this.state.ongoingMerges += 1
   }
   decrementOngoingMerges() {
      if(this.state.ongoingMerges > 0) {
         this.state.ongoingMerges -= 1
      }
   }
   zeroOngoingMerges() {
      this.state.ongoingMerges = 0
   }
   getHighestBallSeen() {
      return this.state.highestBallSeen
   }
   setHighestBallSeen(type) {
      this.state.highestBallSeen = type
   }
   getNextBallType() {
      return this.state.nextBalls[0]
   }
   setNextBalls(arr) {
      this.state.nextBalls = arr
   }
   advanceNextBall(type) {
      // Advance the nextBalls array and push the new value on the end
      for(let i = 0; i < this.state.nextBalls.length - 1; i++) {
         this.state.nextBalls[i] = this.state.nextBalls[i + 1]
      }
      this.state.nextBalls[this.state.nextBalls.length - 1] = type
      // Update the next ball indicator UI
      window.dispatchEvent(new CustomEvent('nextBallUpdated', {
         detail: {
            src: `/headshots/headshot_${this.state.nextBalls[0]}.png`,
            alt: `ball ${this.state.nextBalls[0] + 1}`
         }
      }))
   }
   zeroNextBalls() {
      for(let i = 0; i < this.state.nextBalls.length; i++) {
         this.state.nextBalls[i] = 0
      }
   }
   /**
    * Advance the next ball type to current.
    * Choose the next ball type by considering the highest ball seen and what turn it is.
    * We don't want the player to be able to drop a dozen 0 balls in the first dozen turns,
    * and we also don't want the player to drop a ball that is greater than 1 level higher
    * than the highest ball they've seen.
    * @returns { int } Indicating a ball type. Min value 0, max value 4.
    */
   chooseNewBall() {
      // Advance next ball to current
      const currentBallType = this.state.nextBalls[0]
      // We can't drop a ball larger than type 4
      const maxBallType = 4
      let ballsToChooseFrom
      // If we're deep into the game, just choose the last subarray
      if(this.getHighestBallSeen() >= maxBallType) {
         ballsToChooseFrom = this.state.ballSelection[maxBallType]
      }
      else {
         // Get the highest ball seen, with a max of 4
         const highestBall = Math.min(this.getHighestBallSeen(), maxBallType)
         // Since we're setting a future ball, we need to account for this in the turnGroup
         const turnsInAdvance = this.state.nextBalls.length - 1
         // Every 3 turns, the turnGroup increases by one
         const turnGroup = Math.floor(this.state.turn + turnsInAdvance / 3)
         // Clamp turnGroup to the highest ball seen + 1 (up to a maximum of 4)
         const turnGroupClamped = Math.min(turnGroup, Math.min(highestBall + 1, maxBallType))
         // Our index into the 2D array will be whichever is larger, highestBall or turnGroupClamped
         const choiceIndex = Math.max(highestBall, turnGroupClamped)
         // Choose which set of ball types to randomly choose from this turn
         ballsToChooseFrom = this.state.ballSelection[choiceIndex]
         // Get a random ball types from that set
      }
      const randIndex = Math.floor(Math.random() * ballsToChooseFrom.length)
      const newBallType = ballsToChooseFrom[randIndex]
      this.advanceNextBall(newBallType)
      // If next ball type is greater than highest ball seen, increase highest ball seen to that type
      if(newBallType > this.getHighestBallSeen()) {
         this.setHighestBallSeen(newBallType)
      }

      return currentBallType
   }
   suspendGame() {
      this.state.gameSuspended = true
   }
   unsuspendGame() {
      this.state.gameSuspended = false
   }
   gameIsSuspended() {
      return this.state.gameSuspended
   }
   resetGame() {
      this.state.isResetting = true
      setTimeout(() => {
         this.state.isResetting = false
      }, this.state.chooseBallTimeout)
      
      while(this.state.balls.length > 0) {
         const ball = this.state.balls.pop()
         world.removeBody(ball.body)
         this.getScene().remove(ball.mesh)
      }
      this.state.bodiesToRemove = []
      this.state.needsUpdate = []
      this.state.currentBall = null
      this.zeroPoints()
      this.deactivateDropMode()
      this.state.turn = 0
      this.state.highestBallSeen = 0
      this.zeroNextBalls()
      this.zeroOngoingMerges()
      this.getDropWindow().handleResetGame()
      clearTimeout(this.state.chooseBallTimeoutID)

      this.addCurrentBall(this.getBallFactory().makeBall(0))
      this.getCamera().position.set(0, 40, 30)
      this.unsuspendGame()
      this.state.gameOver = false
   }
   setInStorage(key, value) {
      try {
         localStorage.setItem(key, JSON.stringify(value))
      }
      catch(e) {
         alert('Game could not be saved.')
         console.error(e)
      }
   }
   retrieveFromStorage(key) {
      if(localStorage.getItem(key)) {
         return JSON.parse(localStorage.getItem(key))
      }
      return null
   }
   /**
    * For each ball in play, plus current ball and next ball, we want to extract
    * enough properties so that the state of the game can be faithfully reconstructed.
    * We wrap this in a promise to cover the case where the next ball has not yet been
    * instantiated. If there is no currentBall, we wait until there is one, then save state.
    * 
    * @returns { Object } The saved state of the game.
    */
   async saveState() {
      return new Promise((resolve, reject) => {
         try {
            const savedState = {
               points: this.getPoints(),
               turn: this.getTurn(),
               inPlay: [],
               current: null,
               nextBalls: [],
               highestBallSeen: this.getHighestBallSeen()
            }

            if(this.getCurrentBall()) {
               savedState.current = this.getCurrentBall().body._ballType
               savedState.nextBalls = this.state.nextBalls
               for(const ball of this.getBallsInPlay()) {
                  savedState.inPlay.push({
                     type: ball.body._ballType,
                     position: ball.body.position,
                     isStatic: ball.body.type == 2 ? true : false,
                     expandFunction: null,
                     impulseVector: {x: 0, y: 0, z: 0},
                     quaternion: ball.body.quaternion,
                     velocity: ball.body.velocity
                  })
               }
               this.setInStorage('threekagamesave', savedState)
               window.dispatchEvent(new Event('gameSaved'))
               resolve(savedState)
            }
            else {
               this.getCanvas().addEventListener('ballAvailableToDrop', () => {
                  savedState.current = this.getCurrentBall().body._ballType
                  savedState.nextBalls = this.state.nextBalls
                  for(const ball of this.getBallsInPlay()) {
                     savedState.inPlay.push({
                        type: ball.body._ballType,
                        position: ball.body.position,
                        isStatic: ball.body.type == 2 ? true : false,
                        expandFunction: null,
                        impulseVector: {x: 0, y: 0, z: 0},
                        quaternion: ball.body.quaternion,
                        velocity: ball.body.velocity
                     })
                  }
                  this.setInStorage('threekagamesave', savedState)
                  window.dispatchEvent(new Event('gameSaved'))
                  resolve(savedState)
               }, 
               {
                  once: true
               })
            }
         } catch(err) {
            reject(err)
         }
      })
   }
   async loadSave() {
      const savedState = this.retrieveFromStorage('threekagamesave')
      if(savedState) {
         this.resetGame()
         const currentBall = this.getBallFactory().makeBall(savedState.current)
         this.replaceCurrentBall(currentBall)
         this.setNextBalls(savedState.nextBalls)
         for(const s of savedState.inPlay) {
            const b = this.getBallFactory().makeBall(s.type, s.position, s.isStatic, null, s.impulseVector)
            b.body.quaternion.copy(s.quaternion)
            b.body.velocity.copy(s.velocity)
            this.addBall(b)
         }
         this.incrementPoints(savedState.points)
         this.state.turn = savedState.turn
         this.state.highestBallSeen = savedState.highestBallSeen
         window.dispatchEvent(new CustomEvent('nextBallUpdated', {
            detail: {
               src: `/headshots/headshot_${this.state.nextBalls[0]}.png`,
               alt: `ball ${this.state.nextBalls[0] + 1}`
            }
         }))
      }
   }
   async clearSave() {
      localStorage.removeItem('threekagamesave')
      window.dispatchEvent(new Event('saveCleared'))
   }
}

const stateManager = new StateManager()

export default stateManager