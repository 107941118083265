import { reactive } from 'vue'

export const store = reactive({
   showTitleScreen: true,
   gameInProgress: false,
   showMenuButton: true,
   texturesLoaded: false,
   audioLoaded: false,
   renderShadows: setInitialValue('renderShadows', true),
   musicVolume: setInitialValue('musicVolume', 10),
   effectsVolume: setInitialValue('effectsVolume', 10),
   saveExists: localStorage.getItem('threekagamesave') == null ? false : true
})

function setInitialValue (key, defaultValue) {
   const localStorageValue = localStorage.getItem(key)
   if(localStorageValue === null) {
      return defaultValue
   }
   else {
      if(localStorageValue == 'true') {
         return true
      }
      if(localStorageValue == 'false') {
         return false
      }
      else {
         return parseInt(localStorageValue)
      }
   }
}

window.addEventListener('saveCleared', () => {
   store.saveExists = false
})
window.addEventListener('gameSaved', () => {
   store.saveExists = true
})