<template>
   <div class="cancel-drop-container">
      <button :class="{'cancel-drop-button' : true, visible, activated }" ref="cancelDropButton">&#9587; Cancel drop</button>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const cancelDropButton = ref(null)
const domRect = ref(null)
const visible = ref(false)
const activated = ref(false)

const handleResize = function() {
   if(cancelDropButton.value) {
      domRect.value = cancelDropButton.value.getBoundingClientRect()
   }
}

const handleVisibilityOn = function() {
   visible.value = true
}

const handleVisibilityOff = function() {
   if(activated.value) {
      setTimeout(() => {
         visible.value = false
      }, 500);
   }
   else {
      visible.value = false
   }
}

const handleTouchStart = function() {
   if(cancelDropButton.value) {
      domRect.value = cancelDropButton.value.getBoundingClientRect()
   }
} 

const handleTouchMove = function(event) {
   if(!domRect.value) {
      return false
   }
   for(const touch of event.touches) {
      if(touch.clientX >= domRect.value.left && touch.clientX <= domRect.value.right) {
         if(touch.clientY >= domRect.value.top && touch.clientY <= domRect.value.bottom) {
            if(activated.value == false && visible.value == true) {
               activated.value = true
               setTimeout(() => {
                  activated.value = false
                  visible.value = false
               }, 500);
               window.dispatchEvent(new Event('requestDropModeDeactivation'))
            }
         }
      }
   }
}

onMounted(() => {
   domRect.value = cancelDropButton.value.getBoundingClientRect()
   
   window.addEventListener('resize', handleResize)
   window.addEventListener('requestCancelButtonVisibilityOn', handleVisibilityOn)
   window.addEventListener('requestCancelButtonVisibilityOff', handleVisibilityOff)
   window.addEventListener('touchstart', handleTouchStart)
   window.addEventListener('touchmove', handleTouchMove)
})
onBeforeUnmount(() => {
   window.removeEventListener('resize', handleResize)
   window.removeEventListener('requestCancelButtonVisibilityOn', handleVisibilityOn)
   window.removeEventListener('requestCancelButtonVisibilityOff', handleVisibilityOff)
   window.removeEventListener('touchstart', handleTouchStart)
   window.removeEventListener('touchmove', handleTouchMove)
})

</script>

<style scoped>
.cancel-drop-container {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.cancel-drop-button {
  display: none;
  height: 50px;
  width: 80%;
  background-color: var(--bg-color-dark);
  font-size: 1.2rem;
  border: var(--ui-box-border);
  border-radius: var(--ui-box-border-radius);
  color: var(--text-color-primary);
}
.cancel-drop-button.visible {
  display: block;
}
.cancel-drop-button.activated {
  font-weight: bold;
  animation: disappear 0.5s ease-in-out 0s 1 normal forwards;
}

@keyframes disappear {
  0% {
    width: 80%;
    height: 50px;
    opacity: 1;
  }
  50% {
    width: 85%;
    height: 60px;
    margin-top: 0px;
  }
  100% {
    width: 0%;
    height: 0px;
    margin-top: -30px;
    opacity: 0.2;
  }
}
</style>